import { Box, Container, SimpleGrid } from "@chakra-ui/react";
import React, { useState, useEffect} from "react";

const FeaturedAdBlock = ({ page, block }) => {

    useEffect(() => {
        googletag.cmd.push(function () {
            googletag.pubads().setTargeting("entry-id", page.number_id);
            googletag.pubads().enableSingleRequest();
            googletag.pubads().collapseEmptyDivs();
            googletag.enableServices();

            googletag.display('div-gpt-ad-1725550021431-0');
            googletag.display('div-gpt-ad-1725550052100-0');
            googletag.display('div-gpt-ad-1725550083230-0');

            googletag.pubads().addEventListener('slotRenderEnded', function (ad) {
                if (ad.isEmpty) {
                    const elementId = ad.slot.getSlotElementId();
                    const el = document.getElementById(elementId);
                    if (el) {
                        const adEle = el.parentNode;
                        if (adEle) {
                            adEle.classList.add('is-hidden');
                        }
                    }
                }
            });
        });
    }, []);

    return (
        <Box className="signals-wrapper" pt={10} pb={10}>
            <Container maxW={'7xl'}>
                <SimpleGrid columns={{ base: 1, md: 1, lg: 3 }} spacing={2}>
                    <Box>
                    <div id='div-gpt-ad-1725550021431-0'>
                    </div>
                    </Box>
                    <Box>
                    <div id='div-gpt-ad-1725550052100-0'>
                    </div>
                    </Box>
                    <Box>
                    <div id='div-gpt-ad-1725550083230-0'>
                    </div>
                    </Box>
                </SimpleGrid>
            </Container>
        </Box>
    )
};

export default FeaturedAdBlock;