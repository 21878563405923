import { Box, Container, SimpleGrid } from "@chakra-ui/react";
import React, { useState, useEffect} from "react";

const FpAdBlock = ({ page, block }) => {

    let count = 0;

    const [adsCount, setAdsCount] = useState(6);
    const [adsMdCount, setAdsMdCount] = useState(3);

    useEffect(() => {
        googletag.cmd.push(function () {
            googletag.pubads().setTargeting("entry-id", page.number_id);
            googletag.pubads().enableSingleRequest();
            googletag.pubads().collapseEmptyDivs();
            googletag.enableServices();

            googletag.display('div-gpt-ad-1725549538301-0');
            googletag.display('div-gpt-ad-1725549591422-0');
            googletag.display('div-gpt-ad-1725549674006-0');
            googletag.display('div-gpt-ad-1725549730608-0');
            googletag.display('div-gpt-ad-1725549760008-0');
            googletag.display('div-gpt-ad-1725549787878-0');

            googletag.pubads().addEventListener('slotRenderEnded', function (ad) {
                if (ad.isEmpty) {
                    const elementId = ad.slot.getSlotElementId();
                    if (elementId === 'div-gpt-ad-1725549538301-0' || elementId === 'div-gpt-ad-1725549591422-0' || elementId === 'div-gpt-ad-1725549674006-0' || elementId === 'div-gpt-ad-1725549730608-0' || elementId === 'div-gpt-ad-1725549760008-0' || elementId === 'div-gpt-ad-1725549787878-0') {
                        count++;
                    }
                    const el = document.getElementById(elementId);
                    if (el) {
                        const adEle = el.parentNode;
                        if (adEle) {
                            adEle.classList.add('is-hidden');
                        }
                    }
                }
                if ((6 - count) <= 4) {
                    setAdsMdCount(2);
                }
                setAdsCount(6 - count);
            });
        });
        
    }, []);

    return (
        <Box className="signals-wrapper" pt={10} pb={10}>
            <Container maxW={'7xl'}>
                <SimpleGrid columns={{ base: 2, md: adsMdCount, lg: adsCount }} spacing={2}>
                    <Box className="ad-block">
                        <div id='div-gpt-ad-1725549538301-0' >
                        </div>
                    </Box>
                    <Box className="ad-block">
                        <div id='div-gpt-ad-1725549591422-0'>
                        </div>
                    </Box>
                    <Box className="ad-block">
                        <div id='div-gpt-ad-1725549674006-0'>
                        </div>
                    </Box>
                    <Box className="ad-block">
                        <div id='div-gpt-ad-1725549730608-0'>
                        </div>
                    </Box>
                    <Box className="ad-block">
                        <div id='div-gpt-ad-1725549760008-0'>
                        </div>
                    </Box>
                    <Box className="ad-block">
                        <div id='div-gpt-ad-1725549787878-0'>
                        </div>
                    </Box>
                </SimpleGrid>
            </Container>
        </Box>
    )
};

export default FpAdBlock;