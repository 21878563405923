import * as React from "react";
import {
  Box,
  Text,
  SimpleGrid,
  Input,
  HStack,
  Center,
  FormControl,
  Select,
  Switch,
  Checkbox,
  FormLabel,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

const Recurrence = ({ field, rules, form, ...props }) => {
  const [months, setMonths] = useState(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']);
  const [repeatPeriods, setRepeatPeriods] = useState(['Yearly', 'Monthly', 'Weekly', 'Daily']);
  const [occurrencePeriods, setOccurrencePeriods] = useState(['First', 'Second', 'Third', 'Fourth', 'Last']);
  const [days, setDays] = useState(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Day', 'Weekday', 'Weekend Day']);
  const [selectedRepeatPeriod, setSelectedRepeatPeriod] = useState('');
  const [yearlyFirstSwitch, setYearlyFirstSwitch] = useState(false);
  const [yearlySecondSwitch, setYearlySecondSwitch] = useState(false);
  const [yearlyDays, setYearlyDays] = useState([]);
  const [monthlyDays, setMonthlyDays] = useState([]);
  const [monthlyFirstSwitch, setMonthlyFirstSwitch] = useState(false);
  const [monthlySecondSwitch, setMonthlySecondSwitch] = useState(false);
  const [selectedDays, setSelectedDays] = useState({});

  const onRepeatChange = (e) => {
    form.setFieldValue('recurrence', e.target.value);
    setSelectedRepeatPeriod(e.target.value);
  }

  const onYearlyFirstSwitchChange = () => {
    form.setFieldValue('r_yearly_first', yearlyFirstSwitch);
    setYearlyFirstSwitch(!yearlyFirstSwitch);
    if (yearlyFirstSwitch != yearlySecondSwitch)
      setYearlySecondSwitch(!yearlySecondSwitch);
  }

  const onYearlySecondSwitchChange = () => {
    form.setFieldValue('r_yearly_second', yearlySecondSwitch);
    setYearlySecondSwitch(!yearlySecondSwitch);
    if (yearlyFirstSwitch != yearlySecondSwitch)
      setYearlyFirstSwitch(!yearlyFirstSwitch);
  }

  const onYearlyMonthChange = (e) => {
    form.setFieldValue('r_yearly_month', e.target.value);
    setYearlyDays(getAllDaysInMonth(months.indexOf(e.target.value), 2023));
  }

  const onYearlyDayChange = (e) => {
    form.setFieldValue('r_yearly_day', e.target.value);
  }

  const onYearlyWeekdayChange = (e) => {
    form.setFieldValue('r_yearly_weekday', e.target.value);
  }

  const onYearlySecondMonthChange = (e) => {
    form.setFieldValue('r_yearly_second_month', e.target.value);
  }

  const onMonthlyMonthsChange = (e) => {
    form.setFieldValue('r_monthly_months', e.target.value);
  }

  const onMonthlyFirstDayChange = (e) => {
    form.setFieldValue('r_monthly_day', e.target.value);
  }

  const onMonthlyOccurrenceChange = (e) => {
    form.setFieldValue('r_monthly_occurrence', e.target.value);
  }

  const onMonthlyWeekdayChange = (e) => {
    form.setFieldValue('r_monthly_weekday', e.target.value);
  }

  const onWeeklyWeeksChange = (e) => {
    form.setFieldValue('r_weekly_weeks', e.target.value);
  }

  const onWeeklyDayChange = (e) => {
    if (selectedDays[e.target.value])
      selectedDays[e.target.value] = !selectedDays[e.target.value];
    else
      selectedDays[e.target.value] = true;
    setSelectedDays(selectedDays);
    form.setFieldValue('r_weekly_day', selectedDays);
  }

  const onDailyDaysChange = (e) => {
    form.setFieldValue('r_daily_days', e.target.value);
  }

  const onRecurrenceEndChange = (e) => {
    form.setFieldValue('r_end_date', e.target.value);
  }

  const onMonthlyFirstSwitchChange = () => {
    form.setFieldValue('r_monthly_first', monthlyFirstSwitch);
    setMonthlyFirstSwitch(!monthlyFirstSwitch);
    if (monthlyFirstSwitch != monthlySecondSwitch)
      setMonthlySecondSwitch(!monthlySecondSwitch);
  }

  const onMonthlySecondSwitchChange = () => {
    form.setFieldValue('r_monthly_second', monthlySecondSwitch);
    setMonthlySecondSwitch(!monthlySecondSwitch);
    if (monthlyFirstSwitch != monthlySecondSwitch)
      setMonthlyFirstSwitch(!monthlyFirstSwitch);
  }

  const getAllDaysInMonth = (month, year) =>
    Array.from(
      { length: new Date(year, month + 1, 0).getDate() },
      (_, i) => i + 1
    );

  useEffect(() => {
    window.scrollTo(0, 0);
    setMonthlyDays(getAllDaysInMonth(months.indexOf("Jan"), 2023));
  }, []);

  return (
    <Box>
      <FormControl
        mb="12px"
      >
        <FormLabel htmlFor="repeat_end" ms="4px" fontSize="sm" fontWeight="normal">
          Event Recurrence
        </FormLabel>
        <Select
        id="repeat"
        borderRadius="15px"
        fontSize="sm"
        placeholder="Select Recurrence"
        size="lg"
        onChange={onRepeatChange}>
        {repeatPeriods?.map((t, index) => (
          <option key={`rp-${index}`} value={t.toLowerCase()}>{t}</option>
        ))
        }
      </Select>
      </FormControl>
      {selectedRepeatPeriod == 'yearly' && (
        <Box mt={2}>
          <SimpleGrid columns={4} spacing={2}>
            <Switch onChange={onYearlyFirstSwitchChange} isChecked={yearlyFirstSwitch} align="right" mt={3} />
            <Box>
              <Center mt={2}> on</Center>
            </Box>
            <Select
              id="yearly_month"
              borderRadius="15px"
              fontSize="sm"
              placeholder="Select Month"
              size="lg"
              disabled={!yearlyFirstSwitch}
              onChange={onYearlyMonthChange}>
              {months?.map((t, index) => (
                <option key={`rp-${index}`} value={t}>{t}</option>
              ))}
            </Select>
            <Select
              id="yearly_day"
              borderRadius="15px"
              fontSize="sm"
              placeholder="Select Day"
              size="lg"
              disabled={!yearlyFirstSwitch}
              onChange={onYearlyDayChange}>
              {yearlyDays?.map((t, index) => (
                <option key={`rp-${index}`} value={t}>{t}</option>
              ))}
            </Select>
          </SimpleGrid>
          <SimpleGrid columns={4} spacing={2} mt={2}>
            <Switch onChange={onYearlySecondSwitchChange} isChecked={yearlySecondSwitch} align="right" mt={3} />
            <Box>
              <Center mt={2}> on the</Center>
            </Box>
            <Box>
              <Select
                id="yearly_occurrence"
                borderRadius="15px"
                fontSize="sm"
                placeholder="Select Occurrence"
                size="lg"
                disabled={!yearlySecondSwitch}
                onChange={onYearlyDayChange}>
                {occurrencePeriods?.map((t, index) => (
                  <option key={`rop-${index}`} value={t}>{t}</option>
                ))}
              </Select>
              <Select
                id="yearly_daytext"
                borderRadius="15px"
                fontSize="sm"
                placeholder="Select Day"
                size="lg"
                mt={2}
                disabled={!yearlySecondSwitch}
                onChange={onYearlyWeekdayChange}>
                {days?.map((t, index) => (
                  <option key={`rp-${index}`} value={t}>{t}</option>
                ))}
              </Select>
            </Box>
            <Box>
              <Text mt={2}>of</Text>
              <Select
                id="yearly_month"
                borderRadius="15px"
                fontSize="sm"
                placeholder="Select Month"
                size="lg"
                disabled={!yearlySecondSwitch}
                onChange={onYearlySecondMonthChange}>
                {months?.map((t, index) => (
                  <option key={`rym-${index}`} value={t}>{t}</option>
                ))}
              </Select>
            </Box>
          </SimpleGrid>
        </Box>
      )}
      {selectedRepeatPeriod == 'monthly' && (
        <Box>
          <SimpleGrid columns={3} spacing={2} mt={2}>
            <Box>
              <HStack>
                <Text>every</Text>
                <Input type="number" onChange={onMonthlyMonthsChange}></Input>
                <Text>months</Text>
              </HStack>
            </Box>
            <Box><Switch onChange={onMonthlyFirstSwitchChange} isChecked={monthlyFirstSwitch} /> on day</Box>
            <Select
              id="monthly_day"
              borderRadius="15px"
              fontSize="sm"
              placeholder="Select Day"
              size="lg"
              disabled={!monthlyFirstSwitch}
              onChange={onMonthlyFirstDayChange}>
              {monthlyDays?.map((t, index) => (
                <option key={`rmd-${index}`} value={t}>{t}</option>
              ))}
            </Select>
          </SimpleGrid>
          <SimpleGrid columns={4} spacing={2} mt={2}>
            <Switch onChange={onMonthlySecondSwitchChange} isChecked={monthlySecondSwitch} align="right" mt={3} />
            <Box>
              <Center mt={2}> on the</Center>
            </Box>
            <Box>
              <Select
                id="monthly_occurrence"
                borderRadius="15px"
                fontSize="sm"
                placeholder="Select Occurrence"
                size="lg"
                disabled={!monthlySecondSwitch}
                onChange={onMonthlyOccurrenceChange}>
                {occurrencePeriods?.map((t, index) => (
                  <option key={`rop-${index}`} value={t}>{t}</option>
                ))}
              </Select>
            </Box>
            <Box>
              <Select
                id="monthly_daytext"
                borderRadius="15px"
                fontSize="sm"
                placeholder="Select Day"
                size="lg"
                disabled={!monthlySecondSwitch}
                onChange={onMonthlyWeekdayChange}>
                {days?.map((t, index) => (
                  <option key={`rp-${index}`} value={t}>{t}</option>
                ))}
              </Select>
            </Box>
          </SimpleGrid>
        </Box>
      )}
      {selectedRepeatPeriod == 'weekly' && (
        <Box>
            <Box maxWidth="300px" mt={2}>
              <HStack>
                <Text>every</Text>
                <Input type="number" onChange={onWeeklyWeeksChange}></Input>
                <Text>weeks</Text>
              </HStack>
            </Box>
            <Box mt={2}>
              <HStack>
              <Checkbox size='md' colorScheme='green' onChange={onWeeklyDayChange} value="Mon">
                Mon
              </Checkbox>
              <Checkbox size='md' colorScheme='green' onChange={onWeeklyDayChange} value="Tue">
                Tue
              </Checkbox>
              <Checkbox size='md' colorScheme='green' onChange={onWeeklyDayChange} value="Wed">
                Wed
              </Checkbox>
              <Checkbox size='md' colorScheme='green' onChange={onWeeklyDayChange} value="Thu">
                Thu
              </Checkbox>
              <Checkbox size='md' colorScheme='green' onChange={onWeeklyDayChange} value="Fri">
                Fri
              </Checkbox>
              <Checkbox size='md' colorScheme='green' onChange={onWeeklyDayChange} value="Sat">
                Sat
              </Checkbox>
              <Checkbox size='md' colorScheme='green' onChange={onWeeklyDayChange} value="Sun">
                Sun
              </Checkbox>
            </HStack>
            </Box>
        </Box>
      )}
      {selectedRepeatPeriod == 'daily' && (
        <Box>
            <Box maxWidth="300px" mt={2}>
              <HStack>
                <Text>every</Text>
                <Input type="number" onChange={onDailyDaysChange}></Input>
                <Text>days</Text>
              </HStack>
            </Box>
        </Box>
      )}
      {selectedRepeatPeriod && (
        <FormControl
        mb="12px"
        mt={2}
      >
        <FormLabel htmlFor="repeat_end" ms="4px" fontSize="sm" fontWeight="normal">
          Recurrence End Date
        </FormLabel>
        <Input
          {...field}
          id="repeat_end"
          borderRadius="15px"
          fontSize="sm"
          placeholder="Select Date and Time"
          size="lg"
          type="datetime-local"
          onChange={onRecurrenceEndChange}
        />
      </FormControl>
      )}
    </Box>
  );
};

export default Recurrence;
