import * as React from "react";
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Stack,
  Heading,
  Text,
  Image,
  Icon as ChakraIcon,
  Button,
  Container,
  useToast,
  Link,
  Grid,
  GridItem} from "@chakra-ui/react";
import { PageSlideFade } from "../components/page-transitions";
import { apiHost } from "utils/Cons/Constants";
import axios from "axios";
import { Helmet } from "react-helmet";
import parser from "html-react-parser";
import { MapContainer, TileLayer } from 'react-leaflet';
import ShareModal from "components/share-modal";
import { FaHeart } from "react-icons/fa";


const Event = ({ match }) => {
  const toast = useToast();

  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(true);
  const [variables, setVariables] = useState({});

  const position = [40.7880456, -77.870124];

  const history = useHistory();

  const getEvent = () => {
    let data = {};
    axios.get(`${apiHost}/events/get/${match.params.slug}`)
      .then(res => {
        data = res.data;
        console.log(data);
        if (res.status == 200) {
          let favorites = JSON.parse(localStorage.getItem("favorites")) || [];
          if (favorites.length > 0) {
            data.favorited = favorites.find(f => f.id == data.id && f.type == "event");
          }
          setEvent(data);
          setLoading(false);
        }
        return res;
      })
      .catch(() => {
        setLoading(false);
      });

    return data;
  };


  const getVariables = () => {
    let data = [];
    axios.get(`${apiHost}/variables/get/global`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          console.log(data);
          setVariables(data);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const addFavorite = (destination) => {
    let favorites = JSON.parse(localStorage.getItem("favorites")) || [];
    destination['type'] = "event";
    if (!destination.favorited) {
      favorites.push(destination);
      localStorage.setItem("favorites", JSON.stringify(favorites));
    }
    window.location.href = "/favorites";
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getEvent();
    getVariables();
  }, []);

  return (
    <PageSlideFade>
      <Helmet>
        <title>{event.title}</title>
        <meta name="description" content={event.meta_description} />
      </Helmet>

      {!loading && !event.slug && (
        <Box>
          <Container maxW={'5xl'}>
            <Heading
              as={'h3'}
              fontFamily="american"
              fontWeight={500}
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
              lineHeight={'100%'}
              textAlign="left"
              mt={{ base: 10, md: 5 }}>
              404 Page Not Found
            </Heading>
            <Box textAlign="left">
              <Button as={Link} href="/">Go to Home Page</Button>
            </Box>
          </Container>
        </Box>
      )}

      {event.slug && (
        <Box>
          {event.header_img ? (
            <Box className="home-header-container" backgroundImage={`url(${event.header_img})`} backgroundPosition="center center" backgroundSize="cover" minHeight="60dvh">
              <Container maxW={'5xl'}>

              </Container>
            </Box>
          ) : null}
          <Box bg="#15315A">
            <Container maxW={'5xl'}>
              <Grid templateColumns='repeat(6, 1fr)'>
                <GridItem colSpan={{ base: 12, md: 6 }}>
                  <Text
                    as="h1"
                    textAlign="left"
                    fontFamily="american"
                    fontWeight={500}
                    fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}
                    lineHeight={'100%'}
                    color="#BDECDE"
                    mt={{ base: 4, md: 0 }}
                  >{event.title}</Text>
                </GridItem>
              </Grid>
            </Container>
          </Box>
          <Box bg="#BEE3DA" minHeight="710px">
            <Container maxW={'5xl'}>
              <Stack direction={{ base: 'column', md: 'row' }}>
                <Box w={{ base: '100%', md: '50%', lg: '65%' }} >
                  <Box>
                    {event.main_img ? (
                      <Image
                        src={`${event.main_img}`}
                        layout={'fill'}
                        w={'100%'}
                        roundedBottomLeft={20}
                        alt={'main image'} />
                    ) : null}
                    {!event.main_img && variables.default_event_img ? (
                      <Image
                        src={`${variables.default_event_img}`}
                        layout={'fill'}
                        w={'100%'}
                        roundedBottomLeft={20}
                        alt={'main image'} />
                    ) : null}
                  </Box>
                </Box>
                <Box w={{ base: '100%', md: '50%', lg: '35%' }} textAlign="left" bg="white" pl={10} pr={10} pb={10} roundedBottomRight={20}>
                  <Heading
                    as={'h3'}
                    fontFamily="american"
                    fontWeight={500}
                    fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                    lineHeight={'100%'}
                    textAlign="left"
                    mt={{ base: 10, md: 5 }}>
                    <ChakraIcon as={FaHeart} color={event.favorited ? "red" : "black"} mr={2} cursor="pointer" float="right" onClick={() => { addFavorite(event); }} />
                    {event.title} 
                  </Heading>
                  <Text mb={5} color="gray.500">{event.summary}</Text>
                  <Text>Starts: {event.starts_display}</Text>
                  {event.ends_display ? (
                    <Text>Ends: {event.ends_display}</Text>
                  ) : null}

                  <Stack mt={5}>
                    {event.google_link ? (
                      <Button as={Link} href={`${event.google_link}`} target="_blank" borderTopStartRadius={15} borderBottomStartRadius={15} borderRadius={15} fontSize="0.7em" bg="#F56A18" color="white">Website</Button>
                    ) : null}
                    {event.website ? (
                      <Button as={Link} href={`${event.website}`} target="_blank" borderTopStartRadius={15} borderBottomStartRadius={15} borderRadius={15} fontSize="0.7em" bg="#F56A18" color="white">Website</Button>
                    ) : null}
                    <ShareModal page={event} path="events/" />
                  </Stack>

                </Box>
              </Stack>

              <Box pb={10}>
                {event.content != null ? (
                  <Box bg="white" className="content-block" borderRadius={10} mt={2} p={4} textAlign="left">
                    {parser(event.content)}
                  </Box>
                ) : null}
                <Button as={Link} href={'https://happyvalley.com/events'} borderTopStartRadius={15} borderBottomStartRadius={15} borderRadius={15} fontSize="0.7em" bg="#F56A18" color="white" marginTop={10}>Back to All Events</Button>
              </Box>

            </Container>

            {0 == 1 ? (
              <MapContainer center={position} zoom={12} scrollWheelZoom={false} style={{ minHeight: '80vh' }}>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

              </MapContainer>
            ) : null}
          </Box>

        </Box>
      )}
    </PageSlideFade >
  );
};

export default Event;
