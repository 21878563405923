import * as React from "react";
import {
  Heading,
  Stack,
  IconButton,
  Link,
  Image,
  Box,
  Text,
  useColorModeValue,
  Flex,
  Container,
  SimpleGrid,
  Input,
  Button,
  HStack,
  Center,
  Icon,
  css
} from "@chakra-ui/react";
import siteConfig from "configs/site-config";
import UserIcon from "assets/images/hv-logo-footer.svg";
import DiscoverAmerica from "assets/images/discover-america-purple.svg";
import Dmap from "assets/images/dmap-seal-purple.svg";
import Busa from "assets/images/busa-logo-en-white.svg";
import Pa from "assets/images/pennsylvania-purple.svg";
import Big from "assets/images/cities-big-10-white.png";
import TripAdvisor from "assets/images/tripadvisor-purple.svg";
import { FaLinkedin, FaTwitterSquare, FaYoutubeSquare, FaFacebookSquare, FaInstagramSquare, FaSync, FaArrowCircleUp } from "react-icons/fa";
import { BiMailSend } from "react-icons/bi";

import footerBg from "../assets/images/footer-background.jpg";
import { useState, useEffect } from "react";
import axios from "axios";
import { apiHost, baseUrl } from "utils/Cons/Constants";

const iconProps = {
  variant: "ghost",
  size: "lg",
  isRound: true
};

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

const Footer = () => {
  const [footerLinks1, setFooterLinks1] = useState([]);
  const [footerLinks2, setFooterLinks2] = useState([]);
  const [variables, setVariables] = useState({});
  const year = (new Date()).getFullYear();
  const [isVisible, setIsVisible] = useState(false);

  const getMenu1 = () => {
    let data = {};
    axios.get(`${apiHost}/nav/get/footer_menu_1`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setFooterLinks1(data);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getMenu2 = () => {
    let data = {};
    axios.get(`${apiHost}/nav/get/footer_menu_2`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setFooterLinks2(data);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getVariables = () => {
    let data = [];
    axios.get(`${apiHost}/variables/get/global`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setVariables(data);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  useEffect(() => {
    getMenu1();
    getMenu2();
    getVariables();

    const toggleVisibility = () => {
      if (window.pageYOffset > 1400) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <Box bg={'#17325B'} color={'white'} className="footer" css={css({"@media print": { display: "none" }})}>
      <Container as={Stack} maxW={'6xl'} pt={20} pb={10}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
          <Box>
            <SimpleGrid columns={2} spacing={8}>
              <Box className="footer-link-wrapper">
                {footerLinks1.map((link, index) => (
                  <Link
                    key={`fl1-${index}`}
                    href={link.entry.url}
                  >{link.title}</Link>
                ))}
              </Box>
              <Box className="footer-link-wrapper">
                {footerLinks2.map((link, index) => (
                  <Link
                    key={`fl2-${index}`}
                    href={link.entry.url}
                  >{link.title}</Link>
                ))}
              </Box>
            </SimpleGrid>
          </Box>
          <Box ml={{ base: 0, md: 20}}>
            <Text className="footer-subhead" color="#8989E4" mb={1}>Learn more on</Text>
            <Button className="footer-dispatch" fontSize={{ base: "11px", md: "13px" }} bg="#8989E4" color="#17325B" as={Link} href="https://dispatch.happyvalley.com" target="_blank" mb={5} borderTopStartRadius={30} borderBottomStartRadius={30} borderRadius={30}>The Happy Valley Dispatch</Button>
            <Text className="footer-subhead connect" mt={5} textTransform="uppercase">Connect with us!</Text>
            <HStack spacing={4} mt={1} mb={5}>
              <Link _hover={{ color: "#8A8AE5" }} href={variables.facebook_link} target="_blank">
                <Icon as={FaFacebookSquare} fontSize="5xl" />
              </Link>
              <Link _hover={{ color: "#8A8AE5" }} href={variables.instagram_link} target="_blank">
                <Icon as={FaInstagramSquare} fontSize="5xl" />
              </Link>
              <Link _hover={{ color: "#8A8AE5" }} href={variables.twitter_link} target="_blank">
                <Icon as={FaTwitterSquare} fontSize="5xl" />
              </Link>
              <Link _hover={{ color: "#8A8AE5" }} href={variables.youtube_link} target="_blank">
                <Icon as={FaYoutubeSquare} fontSize="5xl" />
              </Link>
              <Link _hover={{ color: "#8A8AE5" }} href={variables.linkedin_link} target="_blank">
                <Icon as={FaLinkedin} fontSize="5xl" />
              </Link>
            </HStack>
          </Box>
        </SimpleGrid>

        <SimpleGrid columns={1} className="footerlogos">
          <Box>
            <Link href={'/'}>
              <Image src={UserIcon} width="100%" margin='auto' mt={10} alt={'logo image'} />
            </Link>
          </Box>
          <Box>
            <SimpleGrid columns={{base: 2, md: 4}} spacing={10}>
              <Link href={variables.marketing_link} target="_blank" textAlign="right">
                <Image src={Dmap} width="70px" margin="auto" mt={7} alt={'image'} />
              </Link>
              <Link href={variables.discover_america_link} target="_blank">
                <Image src={DiscoverAmerica} width="150px" margin="auto" mt={7} alt={'image'} />
              </Link>
              <Link href={variables.pa_link} target="_blank">
                <Image src={Pa} width="150px" margin="auto" mt={7} alt={'image'} />
              </Link>
              <Link href={variables.bigten_link} target="_blank">
                <Image src={Big} width="150px" margin="auto" mt={7} alt={'image'} />
              </Link>
            </SimpleGrid>
          </Box>
        </SimpleGrid>
        <Box textAlign="center">
          {isVisible && (
            <Box 
              sx={{
                '@media (min-width: 49em)': {
                  position: '-webkit-fixed', 'z-index': 1100, /* Safari */ position: 'fixed', bottom: '20px'
                },
              }}
              padding="5px 10px 5px 10px"
              bg="#8989E4" 
              color="#17325B"
              borderRadius={30}
              left='46.5%'
              >
              <Link onClick={() => window.scrollTo({top: 0, left: 0, behavior: "smooth"})} fontSize="0.8em" className="backtop" zIndex={1200}><Icon as={FaArrowCircleUp} fontSize="1xl" mr="5px" /> Back to Top</Link>
            </Box>
          )}
          {!isVisible && (
            <Link onClick={() => window.scrollTo({top: 0, left: 0, behavior: "smooth"})} fontSize="0.8em" className="backtop" zIndex={1200}><Icon as={FaArrowCircleUp} fontSize="1xl" mr="5px" /> Back to Top</Link>
          )}
          </Box>
      </Container>
      <Box className="copyright" bg="#011E4B" marginBottom="-1px">
        <Center>
          <Text textAlign="center" fontSize={'sm'} p={5} color="#6E76C7" textTransform="uppercase">
            © {year} {variables.organization} | Design & Development by <Link href="https://3twenty9.com" target="_blank" ml={1} _hover={{ color: 'white' }}>3twenty9 Design</Link>
          </Text>
        </Center>
      </Box>
    </Box>
  );
};

export default Footer;
