import { Box, Container, SimpleGrid, Stack } from "@chakra-ui/react";
import React, { useState, useEffect} from "react";

const FcAdBlock = ({ page, block }) => {

    useEffect(() => {
        googletag.cmd.push(function () {
            googletag.pubads().setTargeting("entry-id", page.number_id);
            googletag.pubads().enableSingleRequest();
            googletag.pubads().collapseEmptyDivs();
            googletag.enableServices();

            googletag.display('div-gpt-ad-1725549820308-0');
            googletag.display('div-gpt-ad-1725549850746-0');
            googletag.display('div-gpt-ad-1725549878192-0');

            googletag.pubads().addEventListener('slotRenderEnded', function (ad) {
                if (ad.isEmpty) {
                    const elementId = ad.slot.getSlotElementId();
                    const el = document.getElementById(elementId);
                    if (el) {
                        const adEle = el.parentNode;
                        if (adEle) {
                            adEle.classList.add('is-hidden');
                        }
                    }
                }
            });
        });

    }, []);

    return (
        <Box className="signals-wrapper" pt={5} pb={5}>
            <Container maxW={'8xl'}>
                <Stack direction={{ base: 'column', lg: 'row' }}>
                    <Box w={{ base: '100%', lg: '65%' }} >
                        <div id='div-gpt-ad-1725549820308-0'>
                        </div>
                    </Box>
                    <Box w={{ base: '100%', lg: '32%' }}>
                        <div id='div-gpt-ad-1725549850746-0'>
                        </div>
                        <div id='div-gpt-ad-1725549878192-0'>
                        </div>
                    </Box>
                </Stack>
            </Container>
        </Box>
    )
};

export default FcAdBlock;